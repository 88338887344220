import React, { useEffect } from 'react';

const AuditForm = () => {

    const soSubmit = (el) => {
        if (!soFormValidate(el.id)) {
            return false;
        }
        var behaviour = el.getAttribute('data-behaviour');
        var soBody = document.getElementsByTagName("body")[0];
        var soBodyOriginalStyleHeight = soBody.style.height;
        var soBodyOriginalStyleOverflow = soBody.style.overflow;
        var element = document.createElement("input");
        element.setAttribute("type", "hidden");
        element.setAttribute("name", "referrer");
        element.setAttribute("value", window.location.href);
        el.appendChild(element);
        if (behaviour === 'new_tab') return true;
        if (behaviour === 'modal') return soSubmitModal(el.id);
        if (behaviour === 'be_in_touch') return soSubmitBeInTouch(el.id);
        if (behaviour === 'redirect') return soSubmitRedirect(el.id);
    };

    const soFormValidate = (id) => {
        var domain = document.getElementById("so-domain" + id);
        var email = document.getElementById("so-email" + id);
        var phone = document.getElementById("so-phone" + id);
        var firstName = document.getElementById("so-first-name" + id);
        var lastName = document.getElementById("so-last-name" + id);
        var custom = document.getElementById("so-custom-field" + id);
        var consent = document.getElementById("so-consent-value" + id);
        if (0 === domain.value.length)
            return alert(domain.getAttribute("data-validation")), !1;
        if (domain.value = domain.value.trim().replace(/\/$/, ""), !domain.value.match(/^(https?:\/\/)?[a-z\d\-]{1,62}\..*/i))
            return alert(domain.getAttribute("data-validation")), !1;
        if (null != email) {
            if (0 === email.value.length)
                return alert(email.getAttribute("data-validation")), !1;
            var regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!regex.test(email.value))
                return alert(email.getAttribute("data-validation")), !1;
        }
        if (null != phone && 0 === phone.value.length)
            return alert(phone.getAttribute("data-validation")), !1;
        if (null != firstName && 0 === firstName.value.length)
            return alert(firstName.getAttribute("data-validation")), !1;
        if (null != lastName && 0 === lastName.value.length)
            return alert(lastName.getAttribute("data-validation")), !1;
        if (null != custom && 0 === custom.value.length)
            return alert(custom.getAttribute("data-validation")), !1;
        if (null != consent && !consent.checked)
            return alert(consent.getAttribute("data-validation")), !1;
        return !0;
    };

    const soSubmitModal = (id) => {
        // Implementation for modal behavior
        return true;
    };

    const soSubmitBeInTouch = (id) => {
        // Implementation for be_in_touch behavior
        return true;
    };

    const soSubmitRedirect = (id) => {
        // Implementation for redirect behavior
        return true;
    };
  
    return (
        <div className="so-container">
            <div id="so-boxso1718450351">
                <div id="so-titleso1718450351">Audit Your Website’s SEO Now!</div>
                <form 
                    className="so-widget-form" 
                    id="so1718450351" 
                    action="https://www.websiteauditserver.com/process-embedded.inc" 
                    target="so-iframe" 
                    data-behaviour="modal" 
                    data-title="Website Report for " 
                    data-touch="Thank you for requesting a report. We&#039;ll be in touch shortly!" 
                    onSubmit={() => soSubmit()}
                >
                    <input id="so-typeso1718450351" type="hidden" name="type" value="pdf" />
                    <input type="hidden" name="uid" value="55445" />
                    <input type="hidden" name="behaviour" value="modal" />
                    <input type="hidden" name="template" value="0" />
                    <div id="so-fieldswrapso1718450351">
                        <input type="text" name="domain" id="so-domainso1718450351" className="so-fieldso1718450351" placeholder="Website URL" data-validation="Please enter a correct Website URL. Only homepages are accepted." />
                        <input type="text" name="email" id="so-emailso1718450351" className="so-fieldso1718450351" placeholder="Email" data-validation="Please enter a valid email address." />
                        <input type="submit" id="so-submitso1718450351" value="Check" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AuditForm;