import React from "react"
import Question from "../About/Question."
import "./faq.scss"

const FAQ = ({faqs}) => {
    return (
        <>
            <div className="faqs mt-[21px]">
                <h2 className="text-2xl font-bold leading-9 text-black sm:text-4xl md:leading-[68px] mb-8 text-center">Frequently asked questions</h2>
                {faqs &&
                    faqs?.map((faq, index) => (
                        <Question
                            key={faq.question}
                            data={faq}
                            isFirstItem={index ? false : true}
                        />
                    ))}
            </div>
        </>
    )
}

export default FAQ
