import { Link } from "gatsby"
import React from "react"
import FAQ from "../components/FAQ/FAQ"
import Hero from "../components/Hero/Hero"
import IconBlock from "../components/About/IconBlock"
import TextBlock from "../components/About/TextBlock"
import TheTeam from "../components/About/TheTeam"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import ServiceCTA from "../components/ServiceLanding/CTA/ServiceCTA"
import PartnerShip from "../components/PartnerShip"
import AuditForm from "../components/Form/AuditForm"

function GetStarted({ location }) {
    const heroData = {
        title: "Get started today",
        description:
            "Solving digital challenges with forward-thinking, user-centric and bespoke solutions.",
        sub: ""
    }
    const context = {
        pageName: "SD | Free Audit",
        pageUri: location.href
    }
    return (
        <Layout context={context}>

            <Hero props={heroData} />
            <section className="bg-[#3165F5]/[0.1] py-20 mt-10">
                <div className="container">

                    <TextBlock
                        preHeader="FREE AUDIT"
                        header="Get your free audit today"
                        text={[
                            "Welcome to our Free Website Audit service! Are you looking to enhance your site's SEO and performance? Our comprehensive audit tool provides detailed insights and actionable recommendations to boost your online presence. Simply enter your URL and get started today.",
                            "Keeping your website in top shape is crucial for maintaining high search engine rankings and providing a great user experience."
                        ]}
                    />
                    <AuditForm />
                </div>
            </section>

            <section className="pb-10 lg:pb-20">
            
            </section>
        

            <section className="container">
                <div className="lg:px-40">
                    <FAQ />
                </div>
            </section>
        </Layout>
    )
}
export default GetStarted

export const Head = () => (
    <SEO
        title={"Free Website Audit | Improve SEO and Performance Today | Saigon Digital"}
        description={"Get a comprehensive, free website audit to enhance your site's SEO, speed, and performance. Identify issues and improve your online presence instantly"}
        keywords={
            "Free website audit, SEO audit, Website performance audit, Free SEO analysis, Site speed test, Improve website SEO, Website health check, SEO tools, Free site audit, Website analysis."
        }
    />
)
