import { map, mapValues } from "lodash"
import React, { useState, useRef, useEffect } from "react"
import { animated, useSpring } from "react-spring"

const Question = ({ data, isFirstItem }) => {
    const { question, answer } = data
    const [open, setOpen] = useState(false)
    const [hiddenContentMaxHeight, setHiddenContentMaxHeight] = useState(0)
    const ref = useRef()

    //get the height of hidden content.
    useEffect(() => {
        // First item will open by default
        if (isFirstItem) {
            setOpen(true)
        }

        const handleHideContentMaxHeight = () => {
            ref && setHiddenContentMaxHeight(ref.current?.scrollHeight)
        }
        handleHideContentMaxHeight()
        window.addEventListener("resize", () => handleHideContentMaxHeight())
        return () =>
            window.removeEventListener("resize", handleHideContentMaxHeight())
    }, [])

    const { maxHeight, opacity, backgroundColor, color, scaleY, fill } =
        useSpring({
            maxHeight: open ? `${hiddenContentMaxHeight}px` : "0px",
            opacity: open ? 1 : 0,
            backgroundColor: open ? "#1D2333" : "#F2F2F2",
            color: open ? "#FFFFFF" : "#27282A",
            scaleY: open ? -1 : 1,
            fill: open ? "#FFFFFF" : "#1E1E1E"
        })

    return (
        <>
            <animated.div
                style={{ backgroundColor, color }}
                onClick={() => setOpen(!open)}
                className="mb-5 flex cursor-pointer items-center py-3 pl-3 pr-2 text-base sm:text-lg md:pl-[34px] md:pr-[26px]"
            >
                <p className="mr-auto font-bold  tracking-[-0.025rem]">
                    {question}
                </p>
                <animated.div
                    style={{
                        transform: scaleY.to(scaleY => `scaleY(${scaleY})`)
                    }}
                >
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <animated.path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.243433 1.21962C0.556709 0.908064 1.06324 
                            0.909455 1.3748 1.22273L5.59747 5.46866L9.8434 
                            1.24599C10.1567 0.934427 10.6632 0.935818 10.9748 
                            1.24909C11.2863 1.56237 11.2849 2.0689 10.9717 2.38046L6.15849 
                            7.16726C5.84522 7.47882 5.33869 7.47743 5.02713 7.16415L0.240326 
                            2.35099C-0.0712344 2.03772 -0.0698434 1.53118 0.243433 1.21962Z"
                            style={{ fill }}
                        />
                    </svg>
                </animated.div>
            </animated.div>
            <animated.div
                ref={ref}
                style={{ maxHeight, opacity }}
                className="mx-auto max-w-[93%] overflow-hidden "
            >
                {answer.map((item, idx) => (
                    <div
                        key={idx}
                        className="mb-10"
                        dangerouslySetInnerHTML={{ __html: item }}
                    ></div>
                ))}
            </animated.div>
        </>
    )
}

export default Question
